@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./assets/scss2/custom.scss";

// //Import Light mode
@import "./assets/scss2/style.scss";

//Import Dark RTL mode
// @import './assets/scss2/style-dark-rtl.scss';

//Import RTL mode
// @import './assets/scss2/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss2/style-dark.scss';

// Animate loading dots
.loading-dots {
  text-align: center;
  z-index: 5;

  .dot {
    display: inline;
    margin-left: 0.05em;
    margin-right: 0.05em;
    position: relative;
    font-size: 1.5em;
    top: -4px;
    opacity: 0;
    line-height: 10px;
    animation: showHideDot 2.5s ease-in-out infinite;

    &.one { animation-delay: 0.2s; }
    &.two { animation-delay: 0.4s; }
    &.three { animation-delay: 0.6s; }
  }
}

@keyframes showHideDot {
  0% { opacity: 0; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
}